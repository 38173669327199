// src/components/Sitemap.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Sitemap = () => {
  const siteUrl = "https://library.suiit.ac.in";

  // Define all static and dynamic routes
  const routes = [
    { path: "/", priority: "0.6" },
    { path: "/student", priority: "1.0" },
    { path: "/faculty", priority: "1.0" },
    { path: "/notices", priority: "1.0" },
    { path: "/BookSoftcopy", priority: "1.0" },
    { path: "/gallery", priority: "1.0" },
    { path: "/ContactUs", priority: "1.0" },
    { path: "/AddBookPage", priority: "1.0" },
    { path: "/login", priority: "1.0" },
    { path: "/scanner", priority: "1.0" },
    { path: "/required-books", priority: "1.0" },
    { path: "/contact", priority: "1.0" },
    { path: "/books", priority: "1.0" },
  
  ];

  const today = new Date().toISOString();

  // Generate sitemap as XML
  const generateSitemap = () => {
    const urls = routes
      .map(
        ({ path, priority }) => `
        <url>
          <loc>${siteUrl}${path}</loc>
          <lastmod>${today}</lastmod>
          <priority>${priority}</priority>
        </url>`
      )
      .join("");

    return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${urls}
    </urlset>`;
  };

  // Save XML file for sitemap
  const downloadSitemap = () => {
    const sitemap = generateSitemap();
    const blob = new Blob([sitemap], { type: "application/xml" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "sitemap.xml";
    link.click();
  };

  return (
    <div className="container py-5">
      <div className="card shadow-lg">
        <div className="card-body">
          <h1 className="card-title text-center mb-4">
            Sitemap for <span className="text-primary">library.suiit.ac.in</span>
          </h1>
          <p className="text-center text-muted">
            View and download the sitemap for your library management system.
          </p>

          {/* Download Button */}
          <div className="d-flex justify-content-center mt-4">
            <button
              className="btn btn-success btn-lg"
              onClick={downloadSitemap}
            >
              <i className="bi bi-download"></i> Download Sitemap
            </button>
          </div>

          {/* Display Routes in Table */}
          <div className="table-responsive mt-5">
            <table className="table table-bordered table-hover">
              <thead className="thead-dark">
                <tr>
                  <th>Path</th>
                  <th>Full URL</th>
                  <th>Priority</th>
                  <th>Last Modified</th>
                </tr>
              </thead>
              <tbody>
                {routes.map(({ path, priority }, index) => (
                  <tr key={index}>
                    <td>{path}</td>
                    <td>
                      <a
                        href={`${siteUrl}${path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {siteUrl}{path}
                      </a>
                    </td>
                    <td>{priority}</td>
                    <td>{today}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
