import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import LibraryPage from './Skeleton/LibraryPage';
const PrivateRoute = ({ children, allowedRole, redirectTo = '/' }) => {
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFaculty, setIsFaculty] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        localStorage.setItem("user", JSON.stringify(currentUser));
      } else {
        localStorage.removeItem("user");
      }
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Monitor auth state change
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        // Set user details and email verification status
        setUser(currentUser);
        setEmailVerified(currentUser.emailVerified);

        // Set roles based on the provided conditions
        const isAdmin = (currentUser.email === "22btcse06@suiit.ac.in" || currentUser.email === "library@suiit.ac.in" || currentUser.email === "skpurohit@suiit.ac.in") && currentUser.emailVerified;
        const isFaculty = currentUser.email.endsWith('@suiit.ac.in') && !/\d/.test(currentUser.email) && currentUser.emailVerified;
        const isStudent = currentUser.email.endsWith('@suiit.ac.in') && /\d/.test(currentUser.email) && currentUser.emailVerified;

        setIsAdmin(isAdmin);
        setIsFaculty(isFaculty);
        setIsStudent(isStudent);
      } else {
        setUser(null);
        setIsAdmin(false);
        setIsFaculty(false);
        setIsStudent(false);
        setEmailVerified(false);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  if (loading) {
    return <div><LibraryPage/></div>;
  }

  if (!user || !emailVerified) {
    return <Navigate to="/login" />;
  }

  // Conditional redirects based on the role
  if (allowedRole === 'admin' && !isAdmin) {
    return <Navigate to={redirectTo} />;
  }

  if (allowedRole === 'faculty' && !isFaculty) {
    return <Navigate to={redirectTo} />;
  }

  if (allowedRole === 'student' && !isStudent) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};

export default PrivateRoute;
