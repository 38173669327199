import sha256 from 'crypto-js/sha256';
import { getAuth } from 'firebase/auth'; // Firebase Authentication

const GEOLOCATION_API = 'https://ipapi.co'; // Example: Use ipapi.co for geolocation
const GITHUB_API_URL = 'https://api.github.com/repos/aediain/SUIIT-IP-Traker/contents/ip_data.json';
const GITHUB_TOKEN = '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'; // Use your GitHub token

// Track unique visitors and update the data
export const trackUniqueVisitor = async () => {
  try {
    const ip = await getVisitorIP();
    const hashedIP = sha256(ip).toString(); // Hash the IP for privacy

    // Fetch user data if authenticated (Firebase)
    const userData = await getUserData();
    const email = userData?.email || 'anonymous';
    const hashedEmail = sha256(email).toString(); // Hash the email for privacy

    // Fetch existing data from GitHub
    const existingData = await fetchGitHubData();

    // Combine hashed IP and email as a unique identifier
    const uniqueKey = `${hashedIP}_${hashedEmail}`;

    // Check if the visitor is new
    const isNewVisitor = !existingData.visitors[uniqueKey];

    // Fetch geolocation details
    let geoDetails = await fetchGeolocation(ip);

    // Override geolocation details for specific email domains
    if ((geoDetails.country === 'Unknown' || !geoDetails.country) && email.endsWith('@suiit.ac.in')) {
      geoDetails = { country: 'India', city: 'Sambalpur' };
    }

    const timestamp = new Date().toISOString();

    // Update visitor details
    existingData.visitors[uniqueKey] = {
      ip,
      email,
      ...geoDetails,
      timestamp,
      ...(userData || {}),
    };

    // Increment total count if the visitor is new
    if (isNewVisitor) {
      existingData.totalCount += 1;
    }

    // Save updated data back to GitHub
    await saveGitHubData(existingData);
  } catch (error) {
    console.error('Error tracking unique visitor:', error);
  }
};

// Fetch the visitor's IP address
const getVisitorIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching visitor IP:', error);
    return 'UNKNOWN_IP';
  }
};

// Fetch geolocation data
const fetchGeolocation = async (ip) => {
  try {
    const response = await fetch(`${GEOLOCATION_API}/${ip}/json/`);
    const data = await response.json();

    if (data && data.country_name && data.city) {
      return {
        country: data.country_name || 'Unknown',
        city: data.city || 'Unknown',
      };
    } else {
      return { country: 'Unknown', city: 'Unknown' };
    }
  } catch (error) {
    console.error('Error fetching geolocation data:', error);
    return { country: 'Unknown', city: 'Unknown' };
  }
};

// Fetch user data from Firebase Authentication
const getUserData = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      return {
        displayName: user.displayName || 'Unknown',
        photoURL: user.photoURL || 'Unknown',
        email: user.email || 'Unknown',
      };
    } else {
      return null; // No user is logged in
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

// Fetch existing visitor data from GitHub
const fetchGitHubData = async () => {
  try {
    const response = await fetch(GITHUB_API_URL, {
      headers: {
        Authorization: `Bearer ${GITHUB_TOKEN}`,
      },
    });
    if (!response.ok) {
      if (response.status === 404) {
        return { visitors: {}, totalCount: 0 }; // Initialize if file doesn't exist
      }
      throw new Error(`GitHub API error: ${response.statusText}`);
    }
    const data = await response.json();
    const content = atob(data.content); // Decode base64 content
    return JSON.parse(content);
  } catch (error) {
    console.error('Error fetching GitHub data:', error);
    return { visitors: {}, totalCount: 0 };
  }
};

// Save updated visitor data to GitHub
const saveGitHubData = async (data) => {
  try {
    const existingResponse = await fetch(GITHUB_API_URL, {
      headers: {
        Authorization: `Bearer ${GITHUB_TOKEN}`,
      },
    });
    const sha = existingResponse.ok ? (await existingResponse.json()).sha : undefined;

    const updatedContent = {
      message: 'Update visitor data',
      content: btoa(JSON.stringify(data, null, 2)), // Encode to base64
      sha, // Required if file exists
    };

    const response = await fetch(GITHUB_API_URL, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${GITHUB_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedContent),
    });

    if (!response.ok) {
      throw new Error(`GitHub API error: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error saving GitHub data:', error);
  }
};

// Fetch and display total visitor count
export const fetchTotalVisitorCount = async () => {
  try {
    const data = await fetchGitHubData();
    return data.totalCount || 0;
  } catch (error) {
    console.error('Error fetching total visitor count:', error);
    return 0;
  }
};



