import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import styles from "./css/Navbar.module.css";
import  './css/Navbar.css';
import SUIITLOGO from './images/SUIITLOGO.png';
import userIcon from './images/user.png';
import { auth } from '../firebaseConfig';
import {signOut} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import SearchButton from './SearchButton';


const errorMessages = {
  'auth/invalid-email': 'Please enter a valid email address.',
  'auth/user-disabled': 'This user has been disabled. Please contact support.',
  'auth/user-not-found': 'No account found with this email. Please sign up.',
  'auth/wrong-password': 'Incorrect password. Please try again.',
  'auth/email-already-in-use': 'An account with this email already exists. Please log in.',
  'auth/operation-not-allowed': 'Email/password accounts are not enabled. Please contact support.',
  'auth/weak-password': 'Password should be at least 6 characters.',
  'auth/too-many-requests': 'Too many requests. Please try again later.',
  'auth/network-request-failed': 'Network error. Please check your internet connection.',
  'default': 'An unexpected error occurred. Please try again.'
};

const getFriendlyErrorMessage = (error) => {
  return errorMessages[error.code] || errorMessages['default'];
};

const CustomNavbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef(null);
  const location = useLocation(); // To determine the active page
  const [error, setError] = useState('');
  const handleToggle = () => setIsExpanded(!isExpanded);
  const handleLinkClick = () => setIsExpanded(false);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();


  const [showDropdown, setShowDropdown] = useState({
     login:false
  });

  // General function to handle hover events for both dropdowns
  const handleMouseEnter = (dropdown) => {
    setShowDropdown((prevState) => ({
      ...prevState,
      [dropdown]: true,
    }));
  };

  const handleMouseLeave = (dropdown) => {
    setShowDropdown((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
    
  const isAdmin = user && (user.email === "22btcse06@suiit.ac.in" || user.email === "library@suiit.ac.in") && user.emailVerified;
  const isFaculty = user && user.email.endsWith('@suiit.ac.in') && !/\d/.test(user.email) && user.emailVerified;
  const isStudent = user && user.email.endsWith('@suiit.ac.in') && /\d/.test(user.email) && user.emailVerified;

  

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
         setUser(auth.currentUser)
    }
  }, []);

  

  
  
       
  
  // Close the navbar if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Navbar shadow on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        navbarRef.current.classList.add(styles.navbarShadow);
      } else {
        navbarRef.current.classList.remove(styles.navbarShadow);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setError('');
    } catch (err) {
      const friendlyMessage = getFriendlyErrorMessage(err);
      setError(friendlyMessage);
      setShowModal(true);
    }
  };
  // Styling for the active link
  const getLinkStyle = (path) => {
    const baseStyle = {
      color: "white",
      padding: "12px 20px",
      display: "flex",
      alignItems: "center",
      transition: "transform 0.3s ease",
      borderRadius: "5px",
      backgroundColor: "transparent", // Always transparent
    };
    const activeStyle = {
      fontWeight: "bold",
      color: "#00c896", // Active link color
    };
    return location.pathname === path ? { ...baseStyle, ...activeStyle } : baseStyle;
  };

  const hoverStyle = {
    transform: "scale(1.1)",
    color: "#a4d65e",
  };

  const goToHomeAndRefresh = () => {
    navigate('/'); // Navigate to the home page
    window.location.reload(); // Trigger a page reload after navigation
  };
  return (
    <Navbar
      expand="lg"
      fixed="top"
      ref={navbarRef}
      expanded={isExpanded}
      style={{ backdropFilter: "blur(10px)", // Adds the blur effect
    WebkitBackdropFilter: "blur(10px)", // For Safari support
    boxShadow:'0 1px 5px rgba(0, 0, 0, 0.78) ,0 1px 10px rgb(0, 0, 0)' ,
    marginTop:'-10px',
    backgroundColor:'rgba(0, 0, 0, 0.64)', height:'50px'
   }}
      
      >
    
      <div className="container-fluid mr-0 pr-0"  style={{paddingRight:'-100px' }} >
        <Navbar.Brand as={Link} to="/" className={styles.navbarBrand} >
        <div className="d-flex align-items-center" id="head">
  <img
    src={SUIITLOGO}
    alt="Library Logo"
    style={{ width: '30px', height: 'auto' }}
    id="head"  // Adjust the size of the logo
  />
  <span
    style={{ fontSize: '1rem', color: 'white', marginLeft: '8px' }}
    id="head"  // Adjust the text size and spacing
    onClick={goToHomeAndRefresh}
  >
  Library
  </span>
</div>
 
          <span className={styles.brandText} style={{ fontSize: '1.3rem', color: 'white', }} id='pc' onClick={goToHomeAndRefresh}>SUIIT Library</span> 
        </Navbar.Brand>
        
      <Navbar.Toggle 
  onClick={handleToggle} 
  aria-controls="basic-navbar-nav"
  style={{ border: 'none', background: 'transparent',boxShadow:'none' }} // Optional: make the button background transparent
>


{user ? <NavDropdown
  title={<span><img src={user.photoURL || userIcon} alt={user.email} className="rounded-circle me-2" style={{ height: '25px', width: '25px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} />Account</span>}
  id="login-dropdown"
  className={styles.navDropdown}
>
  { isStudent ? <div><NavDropdown.Item as={Link} to="/student" className="Dropdown">
    <i className="bi bi-person-badge me-2"></i> Student
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div>
  :null}

  { isFaculty ? <div> <NavDropdown.Item as={Link} to="/faculty" className="Dropdown">
    <i className="bi bi-person-workspace me-2"></i> Faculty
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div>
  :null}

  { isAdmin ? <div>  <NavDropdown.Item as={Link} to="/admin" className="Dropdown">
  
    <i className="bi bi-shield-lock me-2"></i> Admin
    
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div> : null}

  <NavDropdown.Item
        onClick={handleSignOut}
        className="Dropdown">
      
      <i className="bi bi-box-arrow-right me-2"></i>Log out

      </NavDropdown.Item>
</NavDropdown> : 
(
<NavDropdown
  title={<span><i className="bi bi-person-circle me-2"></i> Login</span>}
  id="login-dropdown"
  className={styles.navDropdown}
  
>
  <NavDropdown.Item as={Link} to="/student" className="Dropdown">
    <i className="bi bi-person-badge me-2"></i> Student
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

  <NavDropdown.Item as={Link} to="/faculty" className="Dropdown">
    <i className="bi bi-person-workspace me-2"></i> Faculty
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

  <NavDropdown.Item as={Link} to="/admin" className="Dropdown">
 
    <i className="bi bi-shield-lock me-2"></i> Admin
  </NavDropdown.Item>
</NavDropdown>
)}




  {/* <i className="material-icons" style={{ color: 'white', fontSize: '2rem' }}>
    menu
  </i> */}
    </Navbar.Toggle>
           <Nav.Link
              as={Link}
              to="/notices"
              onClick={handleLinkClick}
              style={getLinkStyle("/notices")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/notices"))}
            >
              <i className="material-icons me-2">notifications</i>
            </Nav.Link>
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav.Link>
             <SearchButton/>
            </Nav.Link> 
          <Nav className={`me-auto ${styles.navbarLinks}`} id="hide">
          { !user && !isAdmin ?   <Nav.Link
              as={Link}
              to="/"
              onClick={handleLinkClick}
              style={getLinkStyle("/")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/"))}
            >
              <i className="material-icons me-2 m-3">home</i> Home
            </Nav.Link>
            :null}
            
            { user && isAdmin ? 
            <Nav.Link
              as={Link}
              to="/admin"
              onClick={handleLinkClick}
              style={getLinkStyle("/admin")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/admin"))}
            >
              <i className="material-icons me-2 m-3">admin_panel_settings</i> Admin Panel
            </Nav.Link> :null}
            
            { user && isFaculty && !isAdmin? 
            <Nav.Link
              as={Link}
              to="/faculty"
              onClick={handleLinkClick}
              style={getLinkStyle("/faculty")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/faculty"))}
            >
              <i className="material-icons me-2 m-3">admin_panel_settings</i> Faculty Panel
            </Nav.Link> :null}
            { user && isStudent && !isAdmin ? 
            <Nav.Link
              as={Link}
              to="/student"
              onClick={handleLinkClick}
              style={getLinkStyle("/student")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/student"))}
            >
              <i className="material-icons me-2 m-3">admin_panel_settings</i> Student Panel
              <SearchButton/>
            </Nav.Link> :null}
          
            
      
          </Nav>
         
          


{/* Login Dropdown */}
<div id='hide'>

{user ? <NavDropdown
  title={<span><img src={user.photoURL || userIcon} alt={user.email} className="rounded-circle me-2" style={{ height: '23px', width: '23px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} />Account</span>}
  id="login-dropdown"
  className={styles.navDropdown}
  show={showDropdown.login}
        onMouseEnter={() => handleMouseEnter("login")}
        onMouseLeave={() => handleMouseLeave("login")}
>
  { isStudent ? <div><NavDropdown.Item as={Link} to="/student" className="Dropdown">
    <i className="bi bi-person-badge me-2"></i> Student
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div>
  :null}

  { isFaculty ? <div> <NavDropdown.Item as={Link} to="/faculty" className="Dropdown">
    <i className="bi bi-person-workspace me-2"></i> Faculty
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div>
  :null}

  { isAdmin ? <div>  <NavDropdown.Item as={Link} to="/admin" className="Dropdown">
  
    <i className="bi bi-shield-lock me-2"></i> Admin
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div> : null}

  <NavDropdown.Item
        onClick={handleSignOut}
        className="Dropdown">
      
      <i className="bi bi-box-arrow-right me-2"></i>Log out

      </NavDropdown.Item>
      
</NavDropdown> : 
(
<NavDropdown
  title={<span><button
              className=" mt-2"
              style={{
                border: 'none',
                backgroundColor: '#003366',
                color: 'white',
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
                height: '30px',
                width: '85px',
                borderRadius: '20px',
                display: 'inline-flex',
                alignItems: 'center',
       
                justifyContent: 'center',
              }}
            >
              <i className="bi bi-person-circle me-2" style={{ fontSize: '16px' }}></i>Login
            </button></span>}
  id="login-dropdown"
  className={styles.navDropdown}
  show={showDropdown.login}
        onMouseEnter={() => handleMouseEnter("login")}
        onMouseLeave={() => handleMouseLeave("login")}
        style={{        marginRight:'50px',}}
>
  <div
  style={{
    display: "grid",
    gridTemplateColumns: "1fr 1fr", // Two columns layout
    gap: "15px", 
    padding: "20px", 
    maxWidth: "900px", // Adjusted for the two-column layout
    margin: "auto", 
    backgroundColor: "#ffffff", 
    borderRadius: "15px", 
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.33)",
    fontFamily: "'Roboto', sans-serif", 
  }}
>
  {/* Student Item */}
  <NavDropdown.Item
    as={Link}
    to="/student"
    className="Dropdown hover-effect"
    style={{
      display: "flex",
      alignItems: "center",
      padding: "15px 20px",
      borderRadius: "10px",
      background: "linear-gradient(135deg, #007bff, #0056b3)", // Blue gradient
      color: "#fff",
      transition: "all 0.3s ease-in-out",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    }}
  >
    <i className="bi bi-person-badge me-2"></i> Student
  </NavDropdown.Item>

  <div className="dropdown-divider"></div>

  {/* Faculty Item */}
  <NavDropdown.Item
    as={Link}
    to="/faculty"
    className="Dropdown hover-effect"
    style={{
      display: "flex",
      alignItems: "center",
      padding: "15px 20px",
      borderRadius: "10px",
      background: "linear-gradient(135deg, #28a745, #218838)", // Green gradient
      color: "#fff",
      transition: "all 0.3s ease-in-out",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    }}
  >
    <i className="bi bi-person-workspace me-2"></i> Faculty
  </NavDropdown.Item>

  <div className="dropdown-divider"></div>

  {/* Admin Item */}
  <NavDropdown.Item
    as={Link}
    to="/admin"
    className="Dropdown hover-effect"
    style={{
      display: "flex",
      alignItems: "center",
      padding: "15px 20px",
      borderRadius: "10px",
      background: "linear-gradient(135deg,rgb(255, 128, 0),rgb(255, 0, 0))", // Red gradient
      color: "white",
      transition: "all 0.3s ease-in-out",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    }}
  >
    <i className="bi bi-shield-lock me-2"></i> Admin
  </NavDropdown.Item>
</div>

</NavDropdown>
)}
</div>
{showModal && (
    <div className={`modal ${styles.errorModal}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className={`modal-content ${styles.modalContent}`}>
          <div className="modal-header">
            <h5 className="modal-title">Error</h5>
            <button type="button" className="close" onClick={() => setShowModal(false)}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{error}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )}
        </Navbar.Collapse>
      </div>
    </Navbar>
    
    
  );
};

export default CustomNavbar;
