import React, { useState, useEffect, useRef } from "react";
import { Link,  } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import styles from "./css/HomeNavbar.module.css";
import './css/HomeNavbar.css';
import { auth } from '../firebaseConfig';
import {signOut} from 'firebase/auth';
import userIcon from './images/user.png';
import SearchButton from './SearchButton';

const errorMessages = {
    'auth/invalid-email': 'Please enter a valid email address.',
    'auth/user-disabled': 'This user has been disabled. Please contact support.',
    'auth/user-not-found': 'No account found with this email. Please sign up.',
    'auth/wrong-password': 'Incorrect password. Please try again.',
    'auth/email-already-in-use': 'An account with this email already exists. Please log in.',
    'auth/operation-not-allowed': 'Email/password accounts are not enabled. Please contact support.',
    'auth/weak-password': 'Password should be at least 6 characters.',
    'auth/too-many-requests': 'Too many requests. Please try again later.',
    'auth/network-request-failed': 'Network error. Please check your internet connection.',
    'default': 'An unexpected error occurred. Please try again.'
  };
  
  const getFriendlyErrorMessage = (error) => {
    return errorMessages[error.code] || errorMessages['default'];
  };
const CustomNavbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef(null);
  const [user, setUser] = useState(null);
  const handleToggle = () => setIsExpanded(!isExpanded);
  const [error, setError] = useState('');

    // const [isDarkMode, setIsDarkMode] = useState(false);
    // const toggleButtonRef = useRef(null); // Create a ref for the toggle button
    // const lightIconRef = useRef(null);
    // const darkIconRef = useRef(null);
    const [showModal, setShowModal] = useState(false);

  const isAdmin = user && (user.email === "22btcse06@suiit.ac.in" || user.email === "library@suiit.ac.in" || user.email === "skpurohit@suiit.ac.in") && user.emailVerified;
  const isFaculty = user && user.email.endsWith('@suiit.ac.in') && !/\d/.test(user.email) && user.emailVerified;
  const isStudent = user && user.email.endsWith('@suiit.ac.in') && /\d/.test(user.email) && user.emailVerified;
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);




  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


// const toggleTheme = () => {
//     setIsDarkMode(prevState => !prevState);
//   };
  
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUser(auth.currentUser);
    }
  }, []);

  //  useEffect(() => {
    
      
  //     const savedTheme = localStorage.getItem("theme");
  //     if (savedTheme === "inverted") {
  //       setIsDarkMode(true);
  //     }
  //   }, []);
  
    //  useEffect(() => {
      
      //   const rootElement = document.documentElement;
        
    
      //   if (isDarkMode) {
      //     rootElement.classList.add("inverted");
      //     lightIconRef.current.style.display = "none";
      //     darkIconRef.current.style.display = "inline";
      //     localStorage.setItem("theme", "inverted");
      //   } else {
      //     rootElement.classList.remove("inverted");
      //     lightIconRef.current.style.display = "inline";
      //     darkIconRef.current.style.display = "none";
      //     localStorage.setItem("theme", "normal");
      //   }
      // }, [isDarkMode]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        navbarRef.current.classList.add(styles.navbarShadow);
      } else {
        navbarRef.current.classList.remove(styles.navbarShadow);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSignOut = async () => {
      try {
        await signOut(auth);
        setUser(null);
        setError('');
      } catch (err) {
        const friendlyMessage = getFriendlyErrorMessage(err);
        setError(friendlyMessage);
        setShowModal(true);
      }
    };


  return (
    <Navbar
      className={`${styles.customNavbar}`}
      expand="lg"
      fixed="top"
      ref={navbarRef}
      expanded={isExpanded}
      style={{
    
    padding: isExpanded ? '10px 0' : '0px 0', // Adjust the padding based on the expanded state
    transition: 'padding 0.3s ease', // Smooth transition
    background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.92))', // Semi-transparent blue-black gradient
    zIndex: 1050,
    backdropFilter: "blur(10px)", // Adds the blur effect
    WebkitBackdropFilter: "blur(10px)", // For Safari support
    boxShadow:'0 1px 5px rgb(0,0,0) ,0 2px 10px rgb(0, 0, 0)'
  }}
      
      >
      
    <div className="container-fluid" >
      <Navbar.Toggle
        onClick={handleToggle}
        aria-controls="basic-navbar-nav"
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          padding: '0px',
          marginLeft: '-10px',
        }}
      >
        <i className="bi bi-list" style={{ color: 'white', fontSize: '1.8rem' }}></i>
      </Navbar.Toggle>
  
      <Nav className={`me-auto p-0 m-0 ${styles.navbarLinks}`} id="mob">
       
          <Nav.Link
            style={{
              color: 'white',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              paddingLeft: '2px',
              textAlign: 'center',
            }}
            as={Link} to="/"
          >
           <div className="d-flex align-items-center" id="head">
  
  <span
    style={{ fontSize: '1rem', color: 'white', marginLeft: '8px' }}
    id="head"  // Adjust the text size and spacing
  >
    SUIIT Library
  </span>
</div>


          </Nav.Link>
          
      </Nav>
      <div style={{ display: 'flex', alignItems: 'center', gap: '13px',paddingRight:'20px'}}>
      <SearchButton />
  {/* <div
    ref={toggleButtonRef} 
    onClick={toggleTheme}
    className="btn btn-light"
    style={{
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      transition: 'transform 0.3s ease', 
    }}
    onMouseEnter={() => (toggleButtonRef.current.style.transform = 'scale(1.1)')}
    onMouseLeave={() => (toggleButtonRef.current.style.transform = 'scale(1)')}
  >
    <i
      ref={lightIconRef}
      className="bi bi-sun"
      style={{ color: 'yellow', transition: 'color 0.3s ease' }}
    ></i>
    <i
      ref={darkIconRef}
      className="bi bi-moon"
      style={{
        display: 'none',
        color: 'white',
        transition: 'color 0.3s ease',
      }}
    ></i>
  </div> */}
  
</div>

<div className="position-relative">
      {/* Custom Button */}
      <button
        onClick={handleDropdownToggle}
        ref={buttonRef}
        className="btn btn-link"
        style={{
          border: 'none',
          background: 'transparent',
          boxShadow: 'none',
          cursor: 'pointer',
          zIndex: 10, // Ensure the button is above the dropdown
          transition: 'opacity 0.3s ease',
          padding: '5px', // Add some padding for better click target
          textDecoration: 'none',
        }}
      >
        {user ? (
          <span
            style={{
              color: 'white',
              fontWeight: '500', // More professional font weight
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={user.photoURL || userIcon}
              alt={user.email}
              className="rounded-circle me-2"
              style={{
                height: '24px',
                width: '24px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              }}
            />
            Account
          </span>
        ) : (
          <span
            style={{
              color: 'white',
              fontWeight: '500', // Professional look
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <button
              className="btn d-flex align-items-center"
              style={{
                border: 'none',
                backgroundColor: '#003366',
                color: 'white',
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
                height: '30px',
                width: '85px',
                borderRadius: '20px',
                display: 'inline-flex',
                alignItems: 'center',
                boxShadow: 'none',
                paddingLeft: '5px',
                paddingRight: '5px',
                margin: '0',
                lineHeight: '1',
                justifyContent: 'center',
              }}
            >
              <i className="bi bi-person-circle me-2" style={{ fontSize: '16px' }}></i>Login
            </button>
          </span>
        )}
      </button>

      {/* Dropdown Items (positioned absolutely) */}
      {showDropdown && (
        <div
          ref={dropdownRef}
          className="position-absolute mt-2 p-2"
          style={{
            background: '#fff',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
            zIndex: 999,
            borderRadius: '8px',
            width: '220px',
            color: '#333', // Darker text for better contrast
            animation: 'fadeIn 0.3s ease-in-out',
          }}
        >
          {user ? (
            <>
              {isStudent && (
                <div>
                  <Link
                    to="/student"
                    className="dropdown-item"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 16px',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    <i className="bi bi-person-badge me-2"></i> Student
                  </Link>
                  <div className="dropdown-divider"></div>
                </div>
              )}
              {isFaculty && (
                <div>
                  <Link
                    to="/faculty"
                    className="dropdown-item"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 16px',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    <i className="bi bi-person-workspace me-2"></i> Faculty
                  </Link>
                  <div className="dropdown-divider"></div>
                </div>
              )}
              {isAdmin && (
                <div>
                  <Link
                    to="/admin"
                    className="dropdown-item"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 16px',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    <i className="bi bi-shield-lock me-2"></i> Admin
                  </Link>
                  <div className="dropdown-divider"></div>
                </div>
              )}

              <div>
                <button
                  onClick={handleSignOut}
                  className="dropdown-item"
                  style={{
                    border: 'none',
                    background: 'transparent',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    transition: 'background-color 0.3s ease',
                  }}
                >
                  <i className="bi bi-box-arrow-right me-2"></i> Log out
                </button>
              </div>
            </>
          ) : (
            <>
              <Link
                to="/student"
                className="dropdown-item"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                  transition: 'background-color 0.3s ease',
                }}
              >
                <i className="bi bi-person-badge me-2"></i> Student
              </Link>
              <div className="dropdown-divider"></div>

              <Link
                to="/faculty"
                className="dropdown-item"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                  transition: 'background-color 0.3s ease',
                }}
              >
                <i className="bi bi-person-workspace me-2"></i> Faculty
              </Link>
              <div className="dropdown-divider"></div>

              <Link
                to="/admin"
                className="dropdown-item"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                  transition: 'background-color 0.3s ease',
                }}
              >
                <i className="bi bi-shield-lock me-2"></i> Admin
              </Link>
            </>
          )}
        </div>
      )}
    </div>

      <Navbar.Collapse id="basic-navbar-nav" >
        <NavDropdown
          title={<span><i className="bi bi-house-door me-2"></i>Home</span>}
          id="institute-links-dropdown"
          className="me-3"
        >
          <NavDropdown.Item as={Link} to="./">
            <i className="bi bi-house-door me-2"></i>Home
          </NavDropdown.Item>
   <div className="dropdown-divider"></div>
   <NavDropdown.Item as={Link} to="/about">
               <i className="bi bi-info-circle me-2"></i>About Library
               </NavDropdown.Item>
               <div className="dropdown-divider"></div>
    
               <NavDropdown.Item as={Link} to="/aboutSUIIT">
               <i className="bi bi-info-circle me-2"></i>About SUIIT
               </NavDropdown.Item>
    
                      <div className="dropdown-divider"></div>
                      <NavDropdown.Item as={Link} to="https://suiit.ac.in/institute/suiit-photos">
            <i className="bi bi-image-fill me-2"></i> SUIIT Gallery
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/gallery">
            <i className="bi bi-image me-2"></i> Library Gallery
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/contact">
            <i className="bi bi-globe me-2"></i> Contact Us
          </NavDropdown.Item>
        
          {!user ? (
            <NavDropdown.Item as={Link} to="./login">
              <div className="dropdown-divider"></div>
              <i className="bi bi-box-arrow-in-right me-2"></i> Login
            </NavDropdown.Item>
          ) : null}
        </NavDropdown>
  
       <div
          className="me-2"
          id="hide"
          style={{
            width: '2px',
            backgroundColor: 'rgb(0, 0, 0)',
            height: '35px',
            margin: '0 0px',
          }}
        ></div>
  
        <NavDropdown
          title={<span><i className="bi bi-building me-2"></i> Institute Links</span>}
          id="institute-links-dropdown"
          className="me-3"
        >
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/admission">
            <i className="bi bi-person-check-fill me-2"></i> Admission
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/placement">
            <i className="bi bi-briefcase-fill me-2"></i> Placement
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/institute/suiit-faculty">
            <i className="bi bi-person-lines-fill me-2"></i> Faculty List
          </NavDropdown.Item>
           <div className="dropdown-divider"></div>
                  <NavDropdown.Item as={Link} to="/profiles">
          <i className="bi bi-person-lines-fill me-2"></i> Staff List-Profile
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/institute/committee/anti-ragging">
            <i className="bi bi-shield-lock-fill me-2"></i> Anti Ragging
          </NavDropdown.Item>
          
        </NavDropdown>
 
       <div
          className="me-2"
          id="hide"
          style={{
            width: '2px',
            backgroundColor: 'rgb(0, 0, 0)',
            height: '35px',
            margin: '0 0px',
          }}
        ></div>
  
        <NavDropdown
          title={<span><i className="bi bi-book me-2"></i>E-Resources</span>}
          id="student-resources-dropdown"
          className="me-3"
        >
          <NavDropdown.Item as={Link} to="/notices">
            <i className="bi bi-file-earmark-text me-2"></i> Notices
          </NavDropdown.Item>
          
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/BookSoftcopy">
            <i className="bi bi-book me-2"></i> E-Book
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
  
          <NavDropdown.Item as={Link} to="/bookS">
            <i className="bi bi-book-half me-2"></i> Library Book
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/required-books">
            <i className="bi bi-journal-plus me-2"></i> Request Book
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/scanner">
            <i className="bi bi-qr-code me-2"></i> Scanner
          </NavDropdown.Item>
           <div className="dropdown-divider"></div>
            <NavDropdown.Item as={Link} to="/OSHEC">
              <i className="bi bi-at me-2"></i>OSHEC
            </NavDropdown.Item>
        </NavDropdown>
  
       <div
          className="me-2"
          id="hide"
          style={{
            width: '2px',
            backgroundColor: 'rgb(0, 0, 0)',
            height: '35px',
            margin: '0 0px',
          }}
        ></div>

        <NavDropdown
          title={<span><i className="bi bi-search me-2"></i> OPAC</span>}
          id="student-resources-dropdown"
          className="me-3" 
        >
          
          <NavDropdown.Item as={Link} to="/bookS">
            <i className="bi bi-search me-2"></i> Book Search
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
        
          <NavDropdown.Item as={Link} to="/Student">
            <i className="bi bi-bookmark-fill me-2"></i> Reserve Book
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
        
          <NavDropdown.Item as={Link} to="/FeedbackForm">
            <i className="bi bi-chat-left-text me-2"></i> Book Feedback
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
        
          <NavDropdown.Item as={Link} to="/libraryEvents">
            <i className="bi bi-calendar-event me-2"></i> Library Events
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/journals">
          <div className="dropdown-divider"></div>
            <i className="bi bi-file-earmark-text me-2"></i>Library Journals
            </NavDropdown.Item>
            <div className="dropdown-divider"></div>
            <NavDropdown.Item as={Link} to="https://suiit.ac.in/research/publications">
            <i className="bi bi-file-earmark-text me-2"></i>SUIIT Journals
            </NavDropdown.Item>
        </NavDropdown>
        
  
        <NavDropdown
          title={<span><i className="bi bi-file-earmark-lock me-2"></i> Documents & Policies</span>}
          id="documents-policies"
          className="me-3"
        >
          <NavDropdown.Item as={Link} to="https://drive.google.com/file/d/1X3b2i2CkuofXmRmXQ3Mp3_YT9IxgMjX4/view?usp=sharing">
            <i className="bi bi-file-earmark-text me-2"></i> Information Brochure
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/PrivacyPolicy">
            <i className="bi bi-file-earmark-lock me-2"></i> Privacy Policy
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/TermsandConditions">
            <i className="bi bi-file-earmark-text me-2"></i> Terms & Conditions
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/rti-cell">
            <i className="bi bi-file-earmark-ruled me-2"></i> RTI
          </NavDropdown.Item>
        </NavDropdown>
  
       
  
       
  
 
       
  
        
        <NavDropdown
                  title={<span> <i className="bi bi-file-earmark-text me-2"></i> Notices</span>}
                  id="student-resources-dropdown"
                  className="me-3"
                >
                  <NavDropdown.Item as={Link} to="/notices">
                  <i className="bi bi-file-earmark-text me-2"></i> Notices
                  </NavDropdown.Item>
                  <div className="dropdown-divider"></div>
                  <NavDropdown.Item as={Link} to="https://suiit.ac.in/archives/category/notice">
                  <i className="bi bi-file-earmark-text me-2"></i> SUIIT Notices
                  </NavDropdown.Item>
                  
                </NavDropdown>
                
      </Navbar.Collapse>
    </div>
    {showModal && (
    <div className={`modal ${styles.errorModal}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className={`modal-content ${styles.modalContent}`}>
          <div className="modal-header">
            <h5 className="modal-title">Error</h5>
            <button type="button" className="close" onClick={() => setShowModal(false)}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{error}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )}
  </Navbar>
  
  );
};

export default CustomNavbar;
