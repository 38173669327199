import React, { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css"; // Ensure you have bootstrap-icons installed or imported

const SearchComponent = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchVisible, setIsSearchVisible] = useState(false); // State to manage search bar visibility
    const [totalMatches, setTotalMatches] = useState(0);
    const [currentMatch, setCurrentMatch] = useState(0); // To keep track of the current match
    
    // Function to find and highlight text
    const findMatches = (query) => {
      if (!query) {
        setTotalMatches(0);
        setCurrentMatch(0); // Reset the current match if search query is empty
        return;
      }
    
      const bodyText = document.body.innerText || document.body.textContent;
      const regex = new RegExp(query, "gi"); // Regular expression to match the query case-insensitively
      const matches = bodyText.match(regex);
      setTotalMatches(matches ? matches.length : 0);
      setCurrentMatch(0); // Reset current match to 0 each time the search query changes
    };
    
    // Find and highlight text based on query
    const findString = (str) => {
      if (parseInt(navigator.appVersion) < 4) return;
      let strFound = false;
    
      if (window.find) {
        // CODE FOR BROWSERS THAT SUPPORT window.find
        strFound = window.find(str);
        if (strFound && window.getSelection && !window.getSelection().anchorNode) {
          strFound = window.find(str);
        }
        if (!strFound) {
          strFound = window.find(str, 0, 1);
          while (window.find(str, 0, 1)) continue;
        }
      } else if (navigator.appName.indexOf("Microsoft") !== -1) {
        // EXPLORER-SPECIFIC CODE
        let TRange = document.body.createTextRange();
        strFound = TRange.findText(str);
        if (strFound) TRange.select();
      } else if (navigator.appName === "Opera") {
        alert("Opera browsers not supported, sorry...");
        return;
      }
    
      if (strFound) {
        // Scroll into view
        const selection = window.getSelection();
        if (selection && selection.anchorNode) {
          const range = selection.getRangeAt(0);
          const element = range.commonAncestorContainer.nodeType === 1
            ? range.commonAncestorContainer
            : range.commonAncestorContainer.parentNode;
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
       
        setTotalMatches((prevMatches) => Math.max(prevMatches, 0)); // Reduce the total matches by 1 after a find
        setCurrentMatch((prevMatch) => Math.min(prevMatch , totalMatches)); // Move to the next match
        
      } else {
        console.log(`String '${str}' not found!`);
       
        
      }
    };
    
    const handleSubmit = (e) => {
      e.preventDefault();
      if (searchQuery) {
        findString(searchQuery);
        findMatches(searchQuery);
        handleFindNext(e)
      }
    };
    
    const handleSearchChange = (e) => {
      const query = e.target.value;
      setSearchQuery(query);
      findMatches(query); // Update match count as the user types
    };
    
    const toggleSearchVisibility = () => {
      setIsSearchVisible(!isSearchVisible); // Toggle the search bar visibility
    };
    
    const handleFindNext = () => {
      // Find the next match and update the current match
      if (currentMatch < totalMatches - 1) {
        setCurrentMatch(currentMatch + 1);
        findString(searchQuery); // Move to the next match
      } else {
        console.log("No more matches found.");
      }
    };
    const handleFindPrevious = () => {
        // Find the previous match and update the current match
        if (currentMatch > 0) {
          setCurrentMatch(currentMatch - 1);  // Move to the previous match
          findString(searchQuery);  // Highlight the previous match
        } else {
          console.log("No more matches found.");
        }
      };
    

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
  onClick={toggleSearchVisibility}
  style={{
    position: "relative",
    background: "transparent", // Remove background
    border: "none", // Ensure no border
  }}
>
  <i
    className={`bi ${isSearchVisible ? "bi-x" : "bi-search"}`}
    style={{
      color: "white", // Set the icon color to white
      fontWeight: "bold", // Make the icon bold
      fontSize: "1.5rem", // Adjust size if needed
    }}
  ></i>
</div>


      {isSearchVisible && (
  <div
    style={{
      position: "fixed",
      top: "10px",
      right: "10px",
      zIndex: 9999,
      backgroundColor: "white", // White background
      display: "flex",
      alignItems: "center",
      padding: "10px 15px",
      borderRadius: "20px",
      boxShadow: "0 0px 16px rgb(0, 221, 255)", // Subtle shadow
      gap: "1.5px", // Reduced spacing between elements
      marginTop:'35px'
    }}
  >
    {/* Search Input */}
    <form onSubmit={handleSubmit} className="d-flex" style={{ alignItems: "center", flexGrow: 1 }}>
      <div className="input-group" style={{ position: "relative", flexGrow: 1 }}>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            handleSearchChange(e);
          }}
          className="form-control"
          style={{
            borderRadius: "20px",
            borderColor: "#046655",
            padding: "0.5rem",
            height: "40px",
            paddingLeft: "30px", // Space for icon
          }}
          placeholder="Search..."
        />
        <div
          className="input-group-prepend"
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <i className="bi bi-search" style={{ color: "#046655" }}></i>
        </div>
      </div>
      
    </form>

    {/* Match Counter */}
    {searchQuery && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "blue", // Text color
          marginLeft: "5px", // Reduced spacing
        }}
      >
        <span>{currentMatch}</span>
        <span style={{ margin: "0 2px" }}>/</span> {/* Reduced inner spacing */}
        <span>{totalMatches === 0 ? 0 : totalMatches - 1}</span>
      </div>
    )}

    {/* Previous Button */}
    {totalMatches > 0 && (
      <button
        onClick={handleFindPrevious}
        className="btn d-flex align-items-center justify-content-center"
        style={{
          borderRadius: "50%",
          width: "0px",
          height: "0px",
          background: "transparent",
          color: "black",
          border: "none",
        }}
      >
        <i className="bi bi-chevron-left" style={{ fontSize: "1.2rem" }}></i>
      </button>
    )}

    {/* Next Button */}
    {totalMatches > 0 && (
      <button
        onClick={handleFindNext}
        className="btn d-flex align-items-center justify-content-center"
        style={{
          borderRadius: "50%",
          width: "0px",
          height: "0px",
          background: "transparent",
          color: "black",
          border: "none",
        }}
      >
       
        <i className="bi bi-chevron-right" style={{ fontSize: "1.2rem" }}></i>
       


      </button>
    )}

    {/* Close/Toggle Button */}
    <button
      onClick={toggleSearchVisibility}
      className="btn d-flex align-items-center justify-content-center"
      style={{
        borderRadius: "50%",
        width: "0px",
        height: "0px",
        background: "transparent",
        color: "black",
        border: "none",
      }}
    >
      <i className={`bi ${isSearchVisible ? "bi-x" : "bi-search"}`} style={{ fontSize: "1.2rem" }}></i>
    </button>
  </div>
)}



    </div>
  );
};

export default SearchComponent;
